import 'regenerator-runtime/runtime';
import 'core-js/es6/map';
import 'core-js/es6/set';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';

import {
  FirebaseProvider,
  AuthProvider,
  withRouteAuth,
} from './components/contexts/FirebaseContext';
import {
  apolloClient,
  /*  setTag,
  getTag,*/
} from './components/connect-react-lib/server-api';

import i18n from './i18n';
import livionKeyTheme, {
  secondaryThemeColor as keySecondaryThemeColor,
} from './components/themes/livionkey';
import { primaryThemeColor as accessPrimaryThemeColor } from './components/themes/livionaccess';
import livionAccessTheme from './components/themes/livionaccess';
import configureStore from './redux/ConfigureStore';

import { LivionConnectProvider } from './components/contexts/LivionConnectContext';
import Loader from './components/loaders/Loader';
import NetworkService from './utils/networkService';
import { unregister } from './registerServiceWorker';
import { history } from './utils/history';
import ScrollToTop from './components/common/ScrollToTop';
import './font.css';
import 'sanitize.css';
import jss from 'jss';
import jssNested from 'jss-nested';
import { BUSINESS_FOUND } from './redux/Ui';

jss.use(jssNested());
let key;
if (process.env.REACT_APP_BUSINESS) {
  console.log('BUSINESS is ', process.env.REACT_APP_BUSINESS);
  key = process.env.REACT_APP_BUSINESS === 'key';
  document.title = key ? 'LivionKey' : 'LivionAccess';
  const root = document.getElementById('root');
  root.style.color = key ? keySecondaryThemeColor : accessPrimaryThemeColor;
}

const MaintenanceMessage = lazy(() =>
  import('./containers/MaintenanceMessage')
);
const UiNotifications = lazy(() => import('./containers/UiNotifications'));
const WhatsNew = lazy(() => import('./containers/WhatsNew'));
const RealtimeConnector = lazy(() => import('./containers/RealtimeConnector'));
const Login = lazy(() => import('./containers/Login'));
const Privacy = lazy(() => import('./containers/Privacy'));
const ForgotPassword = lazy(() =>
  import('./components/pages/ForgotPasswordPage')
);
const SelectOrganization = lazy(() =>
  import('./components/pages/SelectOrganization')
);
const Home = lazy(() => import('./containers/Home'));
const AccessDevices = lazy(() => import('./containers/AccessDevices'));
const DeviceInstall = lazy(() => import('./containers/DeviceInstall'));
const Alarms = lazy(() => import('./containers/Alarms'));
const Logs = lazy(() => import('./containers/Logs'));
const Users = lazy(() => import('./containers/Users'));
const User = lazy(() => import('./containers/User'));
const UsageReport = lazy(() => import('./containers/UsageReport'));
const NotFoundPage = lazy(() => import('./containers/NotFoundPage'));
const ErrorPage = lazy(() => import('./containers/ErrorPage'));
const ForbiddenErrorPage = lazy(() =>
  import('./containers/ForbiddenErrorPage')
);
const Contracts = lazy(() => import('./containers/Contracts'));
const Contract = lazy(() => import('./containers/Contract'));
const EditContract = lazy(() => import('./containers/EditContract'));

localStorage.setItem('x-app', 'livionkey');
localStorage.setItem('theme', 'light');

const routes = {
  LOGIN: '/auth/login',
  SELECT_ORGANIZATION: '/selectorganization',
};
const App = ({ client }) => {
  const authCondition = (authUser) => !!authUser;
  return (
    <Router history={history}>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          {key && <MaintenanceMessage />}
          <UiNotifications />
          <Switch>
            <Route component={() => <Privacy />} path="/privacy" />
            <Route
              component={() => <ForgotPassword />}
              path="/auth/forgotpassword"
            />
            <Route
              exact
              path={routes.LOGIN}
              component={() => <Login toRoute={routes.SELECT_ORGANIZATION} />}
            />
            <Route
              exact
              path={routes.SELECT_ORGANIZATION}
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(SelectOrganization)}
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(() => (
                <Home />
              ))}
              path="/home"
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(() => (
                <WhatsNew />
              ))}
              path="/whatsnew"
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(DeviceInstall)}
              path="/install"
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(AccessDevices)}
              path="/locations/:tag/devices"
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(Contract)}
              path="/locations/:tag/contracts/new"
              exact
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(EditContract)}
              path="/locations/:tag/contracts/:contract"
              exact
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(Contracts)}
              path="/locations/:tag/contracts"
              exact
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(Alarms)}
              path="/locations/:tag/alarms"
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(Contracts)}
              path="/contracts"
            />
            <Route
              exact
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )((props) => (
                <Contracts {...props} />
              ))}
              path="/contracts"
            />
            <Route
              exact
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(Users)}
              path="/users"
            />
            <Route
              exact
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )((props) => (
                <User newUser={true} {...props} />
              ))}
              path="/users/new"
            />
            <Route
              exact
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )((props) => (
                <User newUser={false} {...props} />
              ))}
              path="/users/:id"
            />
            <Route
              exact
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )((props) => (
                <UsageReport all={true} {...props} />
              ))}
              path="/stats"
            />
            <Route
              exact
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )((props) => (
                <Alarms {...props} />
              ))}
              path="/alarms"
            />
            <Route
              exact
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )((props) => (
                <Logs {...props} />
              ))}
              path="/logs"
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(RealtimeConnector)}
              path="/devices"
            />
            <Route
              component={withRouteAuth(
                client,
                authCondition,
                routes.LOGIN,
                routes.SELECT_ORGANIZATION
              )(ErrorPage)}
              exact
              path="/error"
            />
            <Route component={ForbiddenErrorPage} exact path="/forbidden" />
            <Redirect exact from="/" to="/home" />
            <Redirect exact from="/login" to={routes.LOGIN} />
            <Route component={() => <NotFoundPage />} />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number

    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  /*
  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }
  */
  // other browser
  return false;
}

var ie = detectIE();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
};

const store = configureStore();
store.dispatch({
  type: BUSINESS_FOUND,
  data: process.env.REACT_APP_BUSINESS || 'key',
});
NetworkService.setupInterceptors(store);
if (window.Cypress) {
  window.store = store;
}
const client = apolloClient(store);

ReactDOM.render(
  <FirebaseProvider {...firebaseConfig}>
    <AuthProvider tagSelect={false} productFilter="livionkey" client={client}>
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <Provider store={store}>
            <ThemeProvider theme={key ? livionKeyTheme : livionAccessTheme}>
              <LivionConnectProvider>
                <I18nextProvider i18n={i18n}>
                  {!ie ? (
                    <App client={client} />
                  ) : (
                    <div>
                      <p>
                        Käytät selainta mitä emme tue. Ole ystävällinen ja
                        päivitä selain osoitteessa.
                        <a href="http://browsehappy.com/">Päivitä!</a>{' '}
                        Suosittelemme Google Chrome -selainta.
                      </p>
                    </div>
                  )}
                </I18nextProvider>
              </LivionConnectProvider>
            </ThemeProvider>
          </Provider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </AuthProvider>
  </FirebaseProvider>,
  document.getElementById('root')
);

unregister();
