import React, { Component } from 'react';
import firebase from './firebase';
import AuthContext from './auth-context';
import { setTag, getTag } from '../../connect-react-lib/server-api';
import { getXRole } from '../../connect-react-lib';
import { authLib } from '../../connect-react-lib/authentication';
import gql from 'graphql-tag';

const getOrganization = () => {
  let organization;
  try {
    organization = JSON.parse(localStorage.getItem('selectedOrganizationUnit'));
    return organization;
  } catch (e) {
    return undefined;
  }
};

class AuthProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    authLib.getCurrentUser((user) => {
      this.setState({
        user: user,
        ready: !!user,
      });
    });
  }
  setAclRole = async (user) => {
    if (process.env.REACT_APP_COMMONAPPS_IN_USE === 'true') {
      const organization = getOrganization();
      if (organization) this.setState({ role: organization.role });
    } else {
      console.log('no common apps role', getXRole());
      this.setState({ role: getXRole() });
    }
  };

  setRolePermissions = async (user) => {
    try {
      let hasRoleSelected = false;
      if (process.env.REACT_APP_COMMONAPPS_IN_USE === 'true') {
        const organization = getOrganization();
        hasRoleSelected = organization && organization.role;
      } else {
        //const claims = await getUserClaims();
        hasRoleSelected = getXRole();
      }
      if (hasRoleSelected) {
        console.log('hasRoleSelected');
        const { data } = await this.props.client.query({
          query: gql`
            query role($role: String) {
              role(role: $role) {
                allows {
                  resources
                  permissions
                }
              }
            }
          `,
        });
        const permissions = data.role.allows.reduce(
          (obj, allow) => ({
            ...obj,
            ...{ [allow.resources]: allow.permissions },
          }),
          {}
        );
        console.log('setRolePermissions', permissions);
        this.setState({ permissions });
      }
    } catch (e) {
      console.error(e);
      // user exist in firebase but not in livion connect, we need to logout and inform user
      const customError = {
        message: `Cannot load user permissions!`,
      };
      throw Object.assign(e, customError);
    }
  };

  verifyLogin = async (user) => {
    try {
      // Next to call backend in order to verify that user exist and is allowed to login to system.
      await this.props.client.query({
        query: gql`
          query verifyLogin {
            verifyLogin {
              id
              displayName
            }
          }
        `,
      });
    } catch (e) {
      console.error(e);
      // user exist in firebase but not in livion connect, we need to logout and inform user
      const customError = {
        message: `Credentials not found in ${window.location.host}, please contact person who gave You access`,
      };
      await this.logout();
      throw Object.assign(e, customError);
    }
  };

  componentDidMount() {
    authLib.onAuthStateChanged(async (user) => {
      console.log('authLib.onAuthStateChanged', user);
      if (!window.location.pathname.includes('forbidden')) {
        if (user) await this.setRolePermissions(user);
        if (user) await this.setAclRole(user);
        if (user !== this.state.user) {
          this.setState({
            user,
            ready: true,
          });
        }
      }
    });
  }

  getUserTags = async () => {
    console.log('TODO getUserTags');
    //const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
    //return decodedToken.claims.userTags;
    return null;
  };

  loginWithProvider = (provider) => {
    return firebase.auth().signInWithPopup(provider);
  };

  login = async (values) => {
    const { email, password } = values;

    this.setState({
      error: null,
      loading: true,
    });

    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      this.setState({
        error: null,
        loading: true,
      });
      if (this.props.actions) this.props.actions.refreshUser();
      await this.verifyLogin(result);
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  loginWithGoogle = async () => {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      /*googleProvider.addScope(
        "https://www.googleapis.com/auth/contacts.readonly"
      );*/
      const result = await this.loginWithProvider(googleProvider);

      this.setState({
        error: null,
        loading: false,
      });
      await this.verifyLogin(result);
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  loginWithFacebook = async () => {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      const facebookProvider = new firebase.auth.FacebookAuthProvider();
      facebookProvider.addScope('user_birthday');

      const result = await this.loginWithProvider(facebookProvider);

      this.setState({
        error: null,
        loading: false,
      });

      await this.verifyLogin(result);
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  _setTag = async (tag, role) => {
    const currentTag = getTag();
    setTag(tag, role);

    await this.setAclRole(firebase.auth().currentUser);

    if (tag !== currentTag && currentTag !== null) {
      await this.props.client.resetStore(); // reset apollo store only when tag change from valid to valid. Not in initialization
    }
  };

  logout = async () => {
    setTag(null);
    await this.props.client.resetStore();
    return firebase.auth().signOut();
  };

  sendPasswordResetEmail = async (values) => {
    this.setState({
      error: null,
      loading: true,
      sent: false,
    });
    try {
      await firebase.auth().sendPasswordResetEmail(values.email);
      this.setState({
        sent: true,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  register = async (values) => {
    this.setState({
      error: null,
      loading: true,
      sent: false,
    });
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password);
      this.setState({
        sent: true,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  getOrganization = ({ tagParents = {} } = {}) => {
    let organization;
    for (let key in tagParents) {
      if (tagParents[key].type === 'organization') {
        organization = { ...tagParents[key] };
        organization.id = key;
        break;
      }
    }
    // build logical name, if selected tag is Area or country, name will be Organization/country/area
    let name = '';
    const tagParentsArr = Object.values(tagParents);
    for (let i = 0; i < tagParentsArr.length; i++) {
      const obj = tagParentsArr[i];
      if (obj.type === 'organization') {
        name = `${obj.name}${name}`;
        break;
      } else {
        name = `/${obj.name}${name}`;
      }
    }
    if (organization) organization.name = name;
    return organization;
  };

  render() {
    const { tagSelect, productFilter } = this.props;
    return (
      <AuthContext.Provider
        value={{
          productFilter,
          tagSelect,
          ...this.state,
          loginWithProvider: this.loginWithProvider,
          login: this.login,
          loginWithGoogle: this.loginWithGoogle,
          loginWithFacebook: this.loginWithFacebook,
          register: this.register,
          sendPasswordResetEmail: this.sendPasswordResetEmail,
          logout: this.logout,
          getUserTags: this.getUserTags,
          selectTag: this._setTag,
          getSelectedTag: getTag,
          getOrganization: this.getOrganization,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
